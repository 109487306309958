<template>
  <div>
    <BaseTable
      class="mt-5 table-shadow"
      :headers="headers"
      :items="infoStatusCampanha"
      :loading="loading"
      :paginate="true"
      :search="search"
      sort-by="statusCampanha"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Status Campanha</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <RegisterBottom
            v-can-access="1667"
            @click="(dialog = true), (labelBtn = 'Salvar')"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.acoes`]="{ item }">
        <!-- <v-btn
          icon
          v-can-access="1669"
          @click="editItem(item)"
          color="orange"
          dark
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-can-access="1670"
          icon
          @click="deleteItem(item)"
          class="ml-2"
          color="red"
          dark
          small
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>-->

        <IconBottom
          :name="'edit'"
          v-can-access="1669"
          @click="editItem(item)"
        />
        <IconBottom
          :name="'delete'"
          v-can-access="1670"
          @click="deleteItem(item)"
        />
      </template>
    </BaseTable>
    <ModalCadStatus
      :dialog="dialog"
      :labelBtn="labelBtn"
      :statusCampanha="statusCampanha"
      @close="refresh()"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import RegisterBottom from "@/components/shared/bottons/RegisterBottom";
import campanha from "@/services/http/campanhaService";
import IconBottom from "@/components/shared/bottons/IconBottom";
import ModalCadStatus from "@/components/sistema/statusCampanha/ModalCadStatus";

export default {
  name: "Tabela",

  components: {
    BaseTable,
    RegisterBottom,
    ModalCadStatus,
    IconBottom
  },
  data() {
    return {
      loading: false,
      dialog: false,
      labelBtn: "Salvar",
      search: "",
      infoStatusCampanha: [],
      statusCampanha: {},
      headers: [
        { text: "ID", value: "id_status" },
        { text: "Status", value: "status" },
        { text: "Ações", value: "acoes", align: "center" }
      ]
    };
  },

  methods: {
    async fetchStatus(filter) {
      this.loading = true;
      const { data } = await campanha()
        .status()
        .show({
          per_page: -1,
          ...filter
        });
      this.infoStatusCampanha = data.data;
      this.loading = false;
    },

    editItem(item) {
      this.statusCampanha = Object.assign({}, item);
      this.dialog = true;
      this.labelBtn = "Editar";
    },
    async deleteItem(item) {
      try {
        await this.$swal.confirm(
          "Deletar Status Campanha",
          `Deseja deletar Status Campanha ${item.status}?`
        );
        campanha()
          .status(item.id_status)
          .delete(
            {},
            { notification: true, message: "Status Campanha deletado" }
          );
        this.refresh();
      } catch (error) {
        this.$notify({
          type: "error",
          text: "Erro ao deletar Status Campanha"
        });
      } finally {
        this.fetchStatus();
      }
    },

    async refresh() {
      this.dialog = false;
      await this.fetchStatus();
    }
  },

  mounted() {
    this.fetchStatus();
  }
};
</script>
